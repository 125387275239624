import Head from 'next/head';
import { ROOT_REDIRECT_URL } from '~/lib/constants';

const redirectAttr = ROOT_REDIRECT_URL ? `0 ${ROOT_REDIRECT_URL}` : '';

/**
 * Root route redirects to stiteknabalik.cz homepage
 */
const Index: React.FC = () => (
  <Head>
    {redirectAttr && <meta httpEquiv="refresh" content={redirectAttr} />}
    <title>stiteknabalik.cz Track &amp; Trace</title>
  </Head>
);

export default Index;
